import {all, call, select} from 'redux-saga/effects';
import {apiCall} from '../../utils/Oauth';
import {logi, logw} from '../logging';
import {saveValue} from '../sharedPrefs';
import {extractIdsOfGivenTypeFromDl} from '../../utils/Utils';
import I18n from 'features/I18n';
import {ACTIVE_ENV} from '../../configs/active-env';
import {getAppSettingsValue} from '../../flows/apps';
import {getSelectedUserGroup} from '../../flows/auth';
import {showToast} from 'features/platformSpecific';

const INIT_DATA_KEY = 'hr_actions';

const GET_INIT_DATA_URLS = [
    {
        key: 'um',
        url: `/opsmgt/v1/um/open`,
        getRequest: (params) => ([{
            username: params.user,
            user_group: params.userGroup,
            app_attribute_label: "logics_employee_id",
            app_label: "hr_actions"
        }]),
        headers: {'message_type': 'hr_actions_init', 'Content-Type': 'application/json'},
        valueGetter: (response) => response[0]
    },
    {
        key: 'logics',
        url: `/opsmgt/v1/logics/open`,
        getRequest: (params) => ({
            facility_mdm_id: params.facilityId,
            language_code: params.langCode
        }),
        headers: {'message_type': 'hr_actions_init', 'Content-Type': 'application/json'}
    }
];

function* loadInitData(user, roles) {
    logi('Preparing to fetch HR Actions init data');
    // extract all data limitation ids from all roles
    const facilityIds = extractIdsOfGivenTypeFromDl(roles, 'Facility');
    const selectedUserGroup = yield select(getSelectedUserGroup);
    const timeoutInSeconds = yield call(getAppSettingsValue, 'hr_actions_init.timeout');
    const timeout = timeoutInSeconds ? timeoutInSeconds * 1000 : 60000;
    const langCode = user.language || 'en';
    const userLogin = user.login;
    if (facilityIds && facilityIds.length && langCode) {
        const params = {
            facilityId: facilityIds[0],
            user: userLogin,
            userGroup: selectedUserGroup,
            langCode
        };

        const calls = GET_INIT_DATA_URLS.map(item => {
            let url = `${ACTIVE_ENV.gatewayUrl}${item.url}`;
            logi(`Fetching HR Actions init data from ${url}`, item.getRequest(params), item.headers);
            return call(apiCall, {url, headers: item.headers, timeout: timeout, method: 'POST', body: JSON.stringify(item.getRequest(params))});
        });
        try {
            const responses = yield all(calls);
            const response = GET_INIT_DATA_URLS.reduce((acc, item, index) => {
                const r = responses[index];
                if (item.valueGetter) {
                    acc[item.key] = item.valueGetter(r);
                } else {
                    acc[item.key] = r;
                }
                return acc;
            }, {});
            logi("HR Actions init data: ", response);
            yield call(saveValue, INIT_DATA_KEY, JSON.stringify(response));
        } catch (e) {
            logw('Error loading HR Actions init data', e);
            showToast(I18n.t('errors.hrActionsDataFailed'));
        }
    } else {
        showToast(I18n.t('errors.hrActionsDataFailed'));
    }
}

export default {
    label: 'hr_actions', // in UM
    title: 'HR Actions',
    restartKey: 'restart_hr_actions',
    activeTaskKey: 'active_task_hr_actions', // {isActive: true/false,...}
    needInitData: true,
    initDataKey: INIT_DATA_KEY,
    urlPath: GET_INIT_DATA_URLS[0].url.substring(GET_INIT_DATA_URLS[0].url.indexOf('opsmgt') + 6),
    validationMessageKey: 'validation_hr_actions',
    loadInitData,
};
